import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useRequestAccountDeletion } from "./api/useRequestAccountDeletion";

const MINIMUM_CHARACTER_COUNT_FOR_REASON_FIELD = 10;

const deleteAccountFormSchema = z.object({
  reason: z.string().min(MINIMUM_CHARACTER_COUNT_FOR_REASON_FIELD, {
    message: `Please provide at least ${MINIMUM_CHARACTER_COUNT_FOR_REASON_FIELD} characters.`,
  }),
  confirmation: z.boolean().refine((value) => value, { message: "You must confirm to proceed." }),
});

interface DeleteAccountDialogProps {
  modalState: UseModalState;
  onSuccess: () => void;
  onFailure: () => void;
}

export function DeleteAccountDialog(props: DeleteAccountDialogProps) {
  const { modalState, onSuccess, onFailure } = props;

  const formMethods = useForm({
    defaultValues: {
      reason: "",
      confirmation: false,
    },
    resolver: zodResolver(deleteAccountFormSchema),
  });

  const {
    formState: { errors, isSubmitting, isValid: formIsValid },
    register,
    handleSubmit,
  } = formMethods;

  const { mutateAsync: requestAccountDeletion, isLoading: isDeletingAccount } =
    useRequestAccountDeletion();

  const { showErrorToast } = useToast();

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>Delete Account</FullScreenDialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <Text>
            Please note, when your account is deleted you will no longer be able to access it, or
            any data associated with the account.
          </Text>
          <br />
          <Text>Additionally, any booked shifts you have will be cancelled.</Text>
          <br />
          <Text>
            If you choose to proceed, we will process your request and notify you with the outcome
            within 45 days.
          </Text>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <TextField
          fullWidth
          multiline
          required
          sx={{ mb: 2 }}
          label="Reason for deletion"
          placeholder={`Minimum ${MINIMUM_CHARACTER_COUNT_FOR_REASON_FIELD} characters`}
          rows={4}
          {...register("reason")}
          error={Boolean(errors.reason)}
          helperText={errors.reason?.message}
        />
        <FormControlLabel
          control={<Checkbox {...register("confirmation")} sx={{ alignSelf: "flex-start" }} />}
          label="I understand that deleting my account is not reversible and I will no longer be able to log in to Clipboard Health."
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column" }}>
        <LoadingButton
          fullWidth
          variant="contained"
          color="error"
          isLoading={isSubmitting || isDeletingAccount}
          disabled={!formIsValid}
          onClick={handleSubmit(async (data) => {
            logEvent(APP_V2_USER_EVENTS.REQUEST_ACCOUNT_DELETION_CONFIRM);

            try {
              await requestAccountDeletion(data);
              onSuccess();
            } catch (error) {
              if (error instanceof AxiosError) {
                if (error.response?.status === 409) {
                  showErrorToast(
                    "You have already requested account deletion. Please wait for it to be processed."
                  );
                }
              } else {
                showErrorToast(
                  "Something went wrong while requesting account deletion. Please try again."
                );
              }

              onFailure();
            }
          })}
        >
          Confirm Account Deletion
        </LoadingButton>
        <Button
          fullWidth
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.REQUEST_ACCOUNT_DELETION_CANCEL_AT_CONFIRM);
            modalState.closeModal();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
}
