import { type UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

export interface DeleteDocumentDialogProps {
  modalState: UseModalState;
  onDelete: () => void;
}

export function DeleteDocumentDialog(props: DeleteDocumentDialogProps) {
  const { modalState, onDelete } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog open={modalIsOpen} onClose={closeModal}>
      <DialogContent dividers>
        <DialogTitle>Delete this document?</DialogTitle>
        <DialogContentText
          sx={{
            paddingX: 2,
          }}
        >
          Are you sure you want to delete this document? This action cannot be undone.
        </DialogContentText>
        <DialogActions>
          <Stack
            spacing={1}
            sx={{
              width: "100%",
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              color="error"
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
              onClick={onDelete}
            >
              Delete
            </Button>
            <Button fullWidth variant="contained" color="primary" onClick={closeModal}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
