import { Text } from "@clipboard-health/ui-react";
import { CircularProgress, Stack } from "@mui/material";
import { environmentConfig, NodeEnvironment } from "@src/appV2/environment";
import { getAppInfo, isCapacitorPlatform } from "@src/appV2/lib";
import { useQuery } from "@tanstack/react-query";

export function AppVersionInfo() {
  const platformIsCapacitor = isCapacitorPlatform();

  const {
    isLoading: appVersionInfoIsLoading,
    isSuccess: appVersionInfoIsSuccess,
    data: appVersionInfo,
  } = useQuery({
    queryKey: ["appVersionInfo"],
    queryFn: async () => {
      const { version: appVersion, otaBuildId } = await getAppInfo();
      return {
        appVersion,
        otaBuildId,
      };
    },
    enabled: platformIsCapacitor,
  });

  if (platformIsCapacitor && appVersionInfoIsLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ marginTop: 2, marginBottom: 4 }}
      >
        <CircularProgress size={20} />
      </Stack>
    );
  }

  const { appVersion, otaBuildId } = appVersionInfoIsSuccess
    ? appVersionInfo
    : { appVersion: undefined, otaBuildId: undefined };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ marginTop: 2, marginBottom: 4 }}
    >
      {platformIsCapacitor && appVersionInfoIsSuccess ? (
        <>
          <Text variant="body2">App Version: {appVersion === "" ? "N/A" : appVersion}</Text>
          <Text variant="body2">OTA Build: {otaBuildId === "" ? "N/A" : otaBuildId}</Text>
        </>
      ) : null}

      {!platformIsCapacitor && process.env.NODE_ENV === NodeEnvironment.PRODUCTION ? (
        <Text variant="body2">Version: {environmentConfig.REACT_APP_VERSION_NAME}</Text>
      ) : null}

      {!platformIsCapacitor && process.env.NODE_ENV === NodeEnvironment.DEVELOPMENT ? (
        <Text variant="body2">Version: Local</Text>
      ) : null}
    </Stack>
  );
}
