import { useToast } from "@src/appV2/lib";
import { useEffect } from "react";

/**
 * This hook is purposed for use at the app entrypoint to display a toast if agentDeletionRequestCancelled exists in the login link
 * */
export function useNotifyDeletionRequestCancelled() {
  const { showInfoToast } = useToast();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agentDeletionRequestCancelled = params.get("agentDeletionRequestCancelled");
    if (agentDeletionRequestCancelled === "true") {
      showInfoToast(
        "Your deletion request has been cancelled because you logged in. If you still wish to delete your account, please submit a new deletion request from the Profile page."
      );
    }
  }, [showInfoToast]);
}
