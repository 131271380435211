import { isDefined } from "@clipboard-health/util-ts";
import { Backdrop, Box, CircularProgress, Stack } from "@mui/material";
import { AppBarHeader, BackButtonLink, NotFoundPage, PageWithHeader } from "@src/appV2/lib";
import { type ReactElement } from "react";

import { CHECKR_REQUIREMENT_ID, PROFESSIONAL_REFERENCE_REQUIREMENT_ID } from "../Documents";
import { useDeleteDocument } from "./api/useDeleteDocument";
import { CameraPermissionRequiredDialog } from "./components";
import { DeleteDocumentDialog } from "./DeleteDocumentDialog";
import { DocumentDetailsPageFooter } from "./DocumentDetailsFooter/Footer";
import { useDocumentUploadContext } from "./hooks";
import { LoadingDocumentDetailsPage } from "./LoadingDocumentDetailsPage/LoadingDocumentDetailsPage";
import { RequirementDetails } from "./RequirementDetails/RequirementDetails";
import { DocumentDetailsRequirementStatus } from "./RequirementStatus";
import { SelectedFileDisplay } from "./SelectedFilesDisplay";
import { useDocumentDetailsContext } from "./useDocumentDetails";

export function DocumentDetailsPage(): ReactElement {
  const {
    requirementId,
    selectedRequirement,
    isLoadingDocumentProperties,
    documentId,
    isLoadingStripeVerificationSession,
    backLink,
    deleteDocumentModalState,
    refreshQueryAndGoBackToDocumentsPage,
    isDocumentPreviewLoading,
  } = useDocumentDetailsContext();
  const {
    isUploading,
    permissionDeniedModalState,
    permissionDeniedSource,
    inputFileRef,
    browserOnFileChange,
  } = useDocumentUploadContext();

  const { mutate: deleteDocument } = useDeleteDocument({
    onSuccess: async () => {
      deleteDocumentModalState.closeModal();
      await refreshQueryAndGoBackToDocumentsPage();
    },
  });

  if (isLoadingDocumentProperties) {
    return <LoadingDocumentDetailsPage backLink={backLink} />;
  }

  if (!isDefined(selectedRequirement)) {
    return <NotFoundPage />;
  }

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title={selectedRequirement?.name ?? ""}
          leftCta={<BackButtonLink defaultBackTo={backLink} />}
        />
      }
      footer={
        [PROFESSIONAL_REFERENCE_REQUIREMENT_ID, CHECKR_REQUIREMENT_ID].includes(
          requirementId ?? ""
        ) || selectedRequirement?.disableWorkerUpload ? undefined : (
          <Box
            sx={{
              /**
               * FIXME - Remove this extra margin. The layout handles the boundary spacing.
               */
              marginBottom: 2,
            }}
          >
            <DocumentDetailsPageFooter />
          </Box>
        )
      }
    >
      <Stack
        sx={{
          marginY: 2,
        }}
        spacing={2}
      >
        <DocumentDetailsRequirementStatus />

        <RequirementDetails />

        <SelectedFileDisplay />
      </Stack>

      <Backdrop
        open={isDocumentPreviewLoading || isUploading || isLoadingStripeVerificationSession}
        sx={(theme) => ({
          color: theme.palette.primary.main,
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {isDefined(permissionDeniedSource) && (
        <CameraPermissionRequiredDialog
          modalState={permissionDeniedModalState}
          cameraSource={permissionDeniedSource}
        />
      )}

      <DeleteDocumentDialog
        modalState={deleteDocumentModalState}
        onDelete={() => {
          if (isDefined(documentId)) {
            deleteDocument(documentId);
          }
        }}
      />

      {/* This hidden input helps us upload files in Browser */}
      <input
        ref={inputFileRef}
        style={{ display: "none" }}
        type="file"
        accept="application/pdf,image/png,image/jpeg,image/jpg"
        onChange={browserOnFileChange}
      />
    </PageWithHeader>
  );
}
