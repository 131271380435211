import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
// eslint-disable-next-line no-restricted-imports
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { useMutation } from "@tanstack/react-query";

interface DeleteAccountConfirmationDialogProps {
  modalState: UseModalState;
}

export function DeleteAccountConfirmationDialog(props: DeleteAccountConfirmationDialogProps) {
  const { modalState } = props;

  const { mutateAsync: signOut, isLoading: isSigningOut } = useMutation({
    mutationFn: async () => {
      const firebaseAuth = getFirebaseSingleton();
      await firebaseAuth.signOut();
    },
  });

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>
        Account Deletion Requested
      </FullScreenDialogTitle>
      <DialogContent>
        <Box sx={{ paddingY: 2 }}>
          <Text>We have received your account deletion request.</Text>
          <br />
          <Text>Please note that it may take up to 45 days to process your account deletion.</Text>
          <br />
          <Text>
            You can cancel your request at any time before it is processed by logging into your
            account.
          </Text>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          fullWidth
          variant="contained"
          isLoading={isSigningOut}
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.REQUEST_ACCOUNT_DELETION_LOGOUT);
            await signOut();
          }}
        >
          Logout
        </LoadingButton>
      </DialogActions>
    </FullScreenDialog>
  );
}
