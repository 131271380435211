import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";

import { DocumentDetailsPageSkeleton } from "../components/DocumentDetailsPageSkeleton";

interface LoadingDocumentDetailsPageProps {
  backLink: string;
}

export function LoadingDocumentDetailsPage(props: LoadingDocumentDetailsPageProps) {
  const { backLink } = props;

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Document Details"
          leftCta={<BackButtonLink defaultBackTo={backLink} />}
        />
      }
    >
      <DocumentDetailsPageSkeleton />
    </PageWithHeader>
  );
}
