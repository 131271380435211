import { type ReactElement } from "react";

import { DocumentUploadProvider } from "./hooks";
import { DocumentDetailsPage } from "./Page";
import { DocumentDetailsProvider } from "./useDocumentDetails";

export function DocumentDetailsPageContainer(): ReactElement {
  return (
    <DocumentDetailsProvider>
      <DocumentUploadProvider>
        <DocumentDetailsPage />
      </DocumentUploadProvider>
    </DocumentDetailsProvider>
  );
}
