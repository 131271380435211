import { DocumentDetailsFooter } from "../components/Footer";
import { DocumentDetailsFooterContextProvider } from "../components/Footer/FooterContext";

export function DocumentDetailsPageFooter() {
  return (
    <DocumentDetailsFooterContextProvider>
      <DocumentDetailsFooter />
    </DocumentDetailsFooterContextProvider>
  );
}
