import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const requestAccountDeletionRequestSchema = z.object({
  reason: z.string(),
});

const requestAccountDeletionResponseSchema = z.object({
  success: z.boolean(),
});

export type RequestAccountDeletionRequest = z.infer<typeof requestAccountDeletionRequestSchema>;
export type RequestAccountDeletionResponse = z.infer<typeof requestAccountDeletionResponseSchema>;

export function useRequestAccountDeletion(
  options: UseMutationOptions<
    RequestAccountDeletionResponse,
    AxiosError,
    RequestAccountDeletionRequest
  > = {}
): UseMutationResult<RequestAccountDeletionResponse, AxiosError, RequestAccountDeletionRequest> {
  return useMutation({
    mutationFn: async (data: RequestAccountDeletionRequest) => {
      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/requestAccountDeletion`,
        data,
        requestSchema: requestAccountDeletionRequestSchema,
        responseSchema: requestAccountDeletionResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.REQUEST_ACCOUNT_DELETION_FAILURE,
    },
    ...options,
  });
}
